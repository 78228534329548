<template>
  <section>
    <!-- <pm-Breadcrumb :home="home" :model="items_bread" /> -->
    <div class="col-md-12">
      <h2 class="titulo-links-escolha">Cadastrar Aluno</h2>
    </div>

    <div class="card card-steps">
      <Step2 :items="items" />
    </div>
    <br />
    <infoBasica @nextPage="nextPage" :dadosPessoa="dadosPessoa" />
  </section>
</template>

<script>
import Step2 from "@/components/steps2.vue";
import infoBasica from "@/components/alunos/infoBasica.vue";

import vueMask from "vue-jquery-mask";
import store_token_info from "@/store/store_token_info.js";

export default {
  props: {
    basic: {},
  },
  components: {
    Step2,
    infoBasica,
  },
  name: "basico",
  data() {
    return {
      home: { icon: "pi pi-home", to: "/admin/painel-adm" },
      items_bread: [{ label: "Cadastrar um Novo Aluno" }],
      dadosPessoa: [],
      items: [
        {
          number: "1",
          label: "Informações básicas",
          to: "aluno-basic-info-cadastrar",
          active: 1,
        },
        {
          number: "2",
          label: "Conectividade e acesso ao sistema",
          to: "2",
          active: 0,
        },
        {
          number: "3",
          label: "Endereço e Transporte",
          to: "3",
          active: 0,
        },
        {
          number: "4",
          label: "Confirmar",
          to: "4",
          active: 0,
        },
      ],
    };
  },
  methods: {
    nextPage(info) {
      this.$router.push({
        name: "login-pessoal-do-aluno-coordenacao",
        params: { basic: JSON.stringify(info) },
      });
    },
  },
  beforeMount() {
    if (this.basic != undefined) {
      const nv = JSON.parse(this.basic);
      this.dadosPessoa = nv.info;
    }

    sessionStorage.setItem("cad",1);
  },
};
</script>

<style>
.row.row-inside {
  max-width: none;
}

.form-control:focus {
  background-color: #f6f7f6;
  border-color: #673ab7;
  box-shadow: none;
}
.form-control {
  background-color: #f6f7f6;
  border: 1px solid #f6f6f6;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

.va-input-wrapper,
fieldset {
  margin-bottom: 0.5rem;
}
</style>
